<div class="container section">
    <h3 class="text-center pt-3 pb-3 section-title">Services</h3>
    <div class="row" style="background-size: cover;">
        <div *ngFor="let tile of tiles" class="col-md-4 col-sm-6 col-xsx-6" style="background-size: cover;">
            <div class="serviceBox" style="background-size: cover;">
                <div class="service-icon" style="background-size: cover;">
                    <span>
                        <img class="entered loaded" [src]="tile.img">
                    </span>
                </div>
                <div class="service-content" style="background-size: cover;">
                    <h5 class="title">{{tile.title}}</h5>
                    <p class="description">{{tile.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>