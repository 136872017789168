import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  tiles = [
    {
      img: 'https://hatigen.com/frontend/images/Ai.png',
      title: 'ARTIFICIAL INTELLIGENCE',
      description: 'Exploit the potential of artificial intelligence with our AI consulting team and develop an AI application to overcome your business challenges.'
    },
    {
      img: 'https://hatigen.com/frontend/images/DataScience.png',
      title: 'DATA SCIENCE',
      description: 'Derive key insights for your business using predictive data analytics and machine learning with our data science consulting services. '
    },
    {
      img: 'https://hatigen.com/frontend/images/DevOps.png',
      title: 'DEVOPS',
      description: 'With our agile practices and DevOps consulting services, attain strategies for your software product development and delivery pipelines.'
    },
    {
      img: 'https://hatigen.com/frontend/images/CloudManagement.png',
      title: 'CLOUD ENABLEMENT',
      description: 'With our cloud consulting services, integrate cloud opportunities into your business development and leverage cloud benefits.'
    },
    {
      img: 'https://hatigen.com/frontend/images/CuberSecurity.png',
      title: 'CYBER SECURITY',
      description: 'We help your company build cyber resilience with our technical, advisory, and strategic cyber security consulting services.'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
