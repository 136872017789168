<div class="section" id="about">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-md-6 pr-md-5 mb-4 mb-md-0">
                <h6 class="section-title mb-4">About Company</h6>
                <p>Accelerating Ultimate Growth of Businesses through SGIT Strategies, Implementation, and Development of Products!</p>
                <img src="../../../assets/about.jpg" alt="" class="w-100 mt-3 shadow-sm">
            </div>
            <div class="col-md-6 pl-md-5">
                <div class="row">
                    <div class="col-6">
                        <img src="../../../assets/about-1.jpg" alt="" class="w-100 shadow-sm">
                    </div>
                    <div class="col-6">
                        <img src="../../../assets/about-2.jpg" alt="" class="w-100 shadow-sm">
                    </div>
                    <div class="col-12 mt-4">
                        <p>Established in 2018, SGIT – renowned as the best IT consulting services company in the UK, India, and France, provide all-around IT services for software development, product implementation, and management. With our combined efforts in IT consulting, staffing, and training, we are set to revolutionize the IT industry with great innovations. Furthermore, we are partnered with top tech companies across the globe offering services in various industry sectors such as banking and finance, public, retail, healthcare, etc.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>