<div class="container section">
    <h3 class="text-center pt-3 pb-3 section-title">Careers</h3>
    <div class="row align-items-center mb30" style="background-size: cover;">
        <div class="col-md-6" style="background-size: cover;">
            <div class="no-padding" style="background-size: cover;">
                <h6 style="margin-bottom: 25px;">
                    SGIT is the place for the most talented people who can make a real impact with their work.
                </h6>
                <p>
                    At SGIT, we encourage a collaborative environment where all employees feel valued for their work
                    and in the team. You will experience the most challenging phase – work at SGIT, which triggers
                    you to work with much dedication and passion, raise your skill sets, and advance in your career.
                </p>
                <p>
                    So, if you are looking for such a work environment and career growth, then SGIT is the place for
                    you.

                </p>
            </div>
        </div>
        <div class="col-md-6 text-middle" style="background-size: cover;">
            <img src="https://hatigen.com/frontend/images/careers/WhyHatigen.jpg" alt=""
                class="img-fluid rounded entered loading" data-ll-status="loading">
        </div>
    </div>
</div>