<div class="container section">
    <h3 class="text-center pt-3 pb-3 section-title">Contact Us</h3>

    <div class="col-lg-12 row">
        <div class="col-lg-6 mt-3">
            <form class="contact-form__card" (submit)="submit($event)">
                <div>
                    <label>Name<sup class="text-danger">*</sup></label>
                    <input type="text" name="name" class="form-control" placeholder="Enter your name" required>
                </div>
                <div class="mt-3">
                    <label>Email<sup class="text-danger">*</sup></label>
                    <input type="email" name="email" class="form-control" placeholder="name@example.com" required>
                </div>
                <div class="mt-3">
                    <label>Message<sup class="text-danger">*</sup></label>
                    <textarea name="message" class="form-control h-auto" rows="5" required></textarea>
                </div>
                <div class="mt-3">
                    <button type="submit" class="btn btn-md btn-dark submit-color" [disabled]="loading">Submit</button>
                </div>
            </form>
        </div>
        <div class="col-lg-6 mt-3">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2476.2500469283805!2d-0.7511996843589709!3d51.63694567965657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487661d291ef568d%3A0x117b658ea70993b6!2s6%20Hamilton%20Rd%2C%20High%20Wycombe%20HP13%205BW%2C%20UK!5e0!3m2!1sen!2sin!4v1668863243217!5m2!1sen!2sin"
                width="100%" height="100%" style="border:0; min-height: 350px; border-radius: 15px" allowfullscreen=""
                loading="lazy"></iframe>
        </div>
        <!-- <div class="col-lg-3 mt-3">
            <div class="contact-form__card">
                <h4>ADDRESS</h4>
                <div class="mt-3">
                    6 Hamilton View, High Wycombe, England, HP13 5FG.
                </div>
                <div class="mt-4">
                    <h4>CALL</h4>
                    <div class="mt-3">
                        +91 7995907921
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>