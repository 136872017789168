<div class="header">
    <div class="overlay">
        <h1 class="title">DevOps Services and Solutions</h1>
        <h1 class="subtitle">Achieve your organizational objectives with our strategic solutions and help your business thrive. Automate workflows, integrate the development and operations team with our DevOps consulting solutions and drive results in no time. </h1>
    </div>
    <div class="shape">
        <svg viewBox="0 0 1500 200">
            <path
                d="m 0,240 h 1500.4828 v -71.92164 c 0,0 -286.2763,-81.79324 -743.19024,-81.79324 C 300.37862,86.28512 0,168.07836 0,168.07836 Z">
            </path>
        </svg>
    </div>
    <div class="mouse-icon" (click)="scrollToBottom()">
        <div class="wheel"></div>
    </div>
</div>