<div class="fixed-header">
  <div class="logo-container">
      <img src="../../assets/logo.svg" class="logo" [ngClass]="{'logo_expand': showlist}">
  </div>
  <div class="main-nav" [ngClass]="{'bgc-black': pageScrolled}">
      <div>
          <span (click)="scroll(home, 1)" [ngClass]="{'active': currentActive === 1}">{{header.home}}</span>
          <span (click)="scroll(about, 2)" [ngClass]="{'active': currentActive === 2}">{{header.about}}</span>
          <span (click)="scroll(services, 3)" [ngClass]="{'active': currentActive === 3}">{{header.services}}</span>
          <span (click)="scroll(careers, 4)" [ngClass]="{'active': currentActive === 4}">{{header.careers}}</span>
          <span (click)="scroll(contact, 5)" [ngClass]="{'active': currentActive === 5}">{{header.contact}}</span>
      </div>
  </div>

  <div class="mobile-nav" [ngClass]="{'bgc-black': pageScrolled || showlist}">
      <div class="text-end" (click)="showlist = !showlist">
          <i class="fa fa-lg" [ngClass]="showlist ? 'fa-times' : 'fa-bars'"></i>
      </div>
      <ul *ngIf="showlist" class="mob-action-items">
          <li (click)="scroll(home, 1)">{{header.home}}</li>
          <li (click)="scroll(about, 2)">{{header.about}}</li>
          <li (click)="scroll(services, 3)">{{header.services}}</li>
          <li (click)="scroll(careers, 4)">{{header.careers}}</li>
          <li (click)="scroll(contact, 5)">{{header.contact}}</li>
      </ul>
  </div>
</div>

<!-- Home section -->
<div #home class="home_container">
  <app-home (scrollEvent)="scrollToBottom($event)"></app-home>
</div>
<!-- About section -->
<div #about class="scroll-mt-65px p-3 about-container">
  <app-about></app-about>
</div>
<!-- Services section -->
<div #services class="scroll-mt-65px p-3 services-container">
  <app-services></app-services>
</div>
<!-- Careers section -->
<div #careers class="scroll-mt-65px p-3 careers-container">
  <app-careers></app-careers>
</div>
<!-- Contact section -->
<div #contact class="scroll-mt-65px p-3 contact-container">
  <app-contact></app-contact>
</div>

<div class="footer">
  <div class="w-100">&copy; 2022 SRI GNAN IT SOLUTIONS LIMITED. ALL RIGHTS RESERVED.</div>
  <div class="social-icons">
      <i class="fa fa-facebook c-pointer" aria-hidden="true"></i>
      <i class="fa fa-linkedin c-pointer" aria-hidden="true"></i>
  </div>
</div>