import { Component, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'InteriorDesign';
  pageScrolled = false;
  @ViewChild('home') home: any;
  @ViewChild('about') about: any;
  @ViewChild('services') services: any;
  @ViewChild('careers') careers: any;
  @ViewChild('contact') contact: any;
  currentActive = 1;
  showlist = false;
  header = {
    home: 'Home',
    about: 'About Us',
    services: 'Services',
    careers: 'Careers',
    contact: 'Contact Us'
  }

  @HostListener('window:scroll', ['$event'])
  onPageScrolled() {
    const currentScroll = window.pageYOffset;
    this.pageScrolled = currentScroll > 30;
    const homeOffsetTop = this.home.nativeElement.offsetTop - 66;
    const aboutOffsetTop = this.about.nativeElement.offsetTop - 66;
    const servicesOffsetTop = this.services.nativeElement.offsetTop - 66;
    const careersOffsetTop = this.careers.nativeElement.offsetTop - 66;
    const contactOffsetTop = this.contact.nativeElement.offsetTop - 66;
    if (window.pageYOffset >= homeOffsetTop && window.pageYOffset < aboutOffsetTop) {
      this.currentActive = 1;
    } else if (window.pageYOffset >= aboutOffsetTop && window.pageYOffset < servicesOffsetTop) {
      this.currentActive = 2;
    } else if (window.pageYOffset >= servicesOffsetTop && window.pageYOffset < careersOffsetTop) {
      this.currentActive = 3;
    } else if (window.pageYOffset >= careersOffsetTop && window.pageYOffset < contactOffsetTop) {
      this.currentActive = 4;
    } else if (window.pageYOffset >= contactOffsetTop) { //Remove this else if condition if clients is added.
      this.currentActive = 5;
    } else {
      this.currentActive = 0;
    }
  }

  scroll(el: HTMLElement, index: number) {
    el.scrollIntoView();
    this.showlist = false;
  }

  scrollToBottom(_event: any) {
    this.scroll(this.contact.nativeElement, 5);
  }

}
